import React, { useEffect, useRef, useState, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { ApiService } from "../Services/apiservices";
import constant from "../Services/constant";
function HomeSlider() {
  const [bannerData, setbannerData] = useState([]);
  const [Imgpath, setImgpath] = useState("");
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getSliderData();
    }
    didMountRef.current = false;
  });
  const getSliderData = () => {
    ApiService.fetchData("home-top-slider").then((response) => {
      if (response.status === "success") {
        setbannerData(response?.sliders);
        setImgpath(response?.slider_url);

      }
    });
  };

  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className='main-slider'>
      <Swiper
        spaceBetween={0}
        breakpoints={{
          0: {
            slidesPerView: 1
          },
          600: {
            slidesPerView: 1
          },
          1000: {
            slidesPerView: 1
          }
        }}
        modules={[Autoplay]}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        ref={sliderRef}
      >
                          {bannerData?.map((list, index) => {
                    return (
        <SwiperSlide key={index}>
          <div className='sliderItem'>
            <div className='sliderbg' style={{ background: list.slider_image ? `url(${Imgpath}${list.slider_image})` : `url${constant.DEFAULT_IMAGE}` }}></div>
            <div className='sliderItem-content'>
              <p  dangerouslySetInnerHTML={{
                                    __html: list.slider_desc,
                                  }}></p>
              <a href="#" className="cleenhearts-btn mt-30">
                <div className="cleenhearts-btn__icon-box">
                  <div className="cleenhearts-btn__icon-box__inner"><span className="icon-donate"><i className="ri-service-line mr-5"></i></span></div>
                </div>
                <span className="cleenhearts-btn__text">Donate Now</span>
              </a>
            </div>
          </div>
        </SwiperSlide>


);
})}
        {/* <SwiperSlide>
          <div className='sliderItem'>
            <div className='sliderbg' style={{ background: 'url(https://bracketweb.com/cleenhearts-html/assets/images/backgrounds/slider-1-2.jpg)' }}></div>
            <div className='sliderItem-content'>
              <div className='sc-subtitle'>Give a helping hand for a child</div>
              <h2>Lend a helping hand to who those need it</h2>
              <p>Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.</p>
              <a href="#" className="cleenhearts-btn mt-30">
                <div className="cleenhearts-btn__icon-box">
                  <div className="cleenhearts-btn__icon-box__inner"><span className="icon-donate"><i className="ri-service-line mr-5"></i></span></div>
                </div>
                <span className="cleenhearts-btn__text">Donate Now</span>
              </a>
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>
      {/* <div className="swiper-buttons__box">
        <div className="swiper-button swiper-button-prev" onClick={handlePrev}>
          <span>
            <svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 14L26 14" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path><path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path></svg>
          </span>
        </div>
        <div
          className="swiper-button swiper-button-next"
          onClick={handleNext}
        >
          <span>
            <svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 14L26 14" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path><path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path></svg>
          </span>
        </div>
      </div> */}
    </div>
  )
}

export default HomeSlider