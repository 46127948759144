import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import constant from "../Services/constant";
import { Autoplay} from "swiper/modules";
function HomePartner() {
    const didMountRef = useRef(true);
  const [partnerData, setpartnerData] = useState([]);
  const [partnerImg, setpartnerImg] = useState("");

  useEffect(() => {
    if (didMountRef.current) {
      getpartnerData();
    }
    didMountRef.current = false;
  }, []);

  const getpartnerData = () => {
    ApiService.fetchData("partner-list").then((res) => {
      console.log(res);
      if (res.status === "success") {
          setpartnerData(res.partnerList)
          setpartnerImg(res.partner_url)

      }
    });
  };

    const PartnerCarouselOptions = {
        loop: true,
        spaceBetween: 15,
        breakpoints: {
          0: {
            slidesPerView: 2, 
          },
          600: {
            slidesPerView: 2, 
          },
          1000: {
            slidesPerView: 6, 
          },
          1200: {
            slidesPerView: 6, 
          },
       
        },
        
      };

  return (
    <>
    <section className='section-gap-md partner'>
      <div className='container-fluid'>
      <div className="section-title section-center mb-40">
          <h2>Our <span>Partners</span></h2>
         </div>
        <div className='row'>
          <div className='col-lg-12'>
          <Swiper
    {...PartnerCarouselOptions}
    modules={[Autoplay]}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
  >
{partnerData.map((value, index) => {
  return (
    <SwiperSlide key={index}>
  <div className='partner-box'>
    <a href={value.client_link} target="_blank">
      <img src={
          value.client_image != null
            ? partnerImg + value.client_image
            : constant.DEFAULT_IMAGE
        }></img>
    </a>
  </div>
 </SwiperSlide>
   );
  })}
 </Swiper>
          </div>
        </div>
      </div>


    </section>
     </>
  )
}

export default HomePartner