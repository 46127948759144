import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { useParams } from 'react-router-dom'
import constant from '../../Components/Services/constant'

function Programs() {
    const{slug} = useParams()
    const[programData,setProgramData] = useState({})
    const[ImageUrl,setImageUrl] = useState("")

    const didMountRef = useRef(true)

    useEffect(()=>{
        if(didMountRef.current){
            getPogramDetail()
        }
        didMountRef.current = false
    })

   const getPogramDetail = () =>{

    const dataString = {
        "program_slug" : slug
    }
    ApiService.postData("program-details",dataString).then((res)=>{
        console.log(res)
        if(res.status === "success"){
            setProgramData(res.programDetails)
            setImageUrl(res.program_img_url)

        }
 



    })
 
   } 

  return (
    <>
   <Header/> 
   <div className="subheader">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <h1>{programData.program_name}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{programData.program_name}</li>
                        </ol>
                        </nav>
                </div>
            </div>
        </div>
        <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div>
    </div>



          {/* <img src={programData.program_image!=null ? ImageUrl + programData.program_image : constant.DEFAULT_IMAGE} alt={programData.program_image}/>
    <h1>{programData.program_name}</h1> */}
    <div dangerouslySetInnerHTML={{__html:programData.program_desc}}></div>



    <Footer/>
    </>
   
  )
}

export default Programs