import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import constant from "../Services/constant";
function HomeBlogs() {
  const didMountRef = useRef(true);
  const [blogsdata, setBlogsData] = useState([]);
  const [blogImgUrl, setBlogImgUrl] = useState("");

  useEffect(() => {
    if (didMountRef.current) {
      FeaturedBlogdata();
    }
    didMountRef.current = false;
  }, []);

  const FeaturedBlogdata = () => {
    ApiService.fetchData("featured-blogs").then((res) => {
      console.log(res);
      if (res.status === "success") {
        setBlogsData(res.blogData);
        setBlogImgUrl(res.blog_url);
      }
    });
  };


  return (
    <section className="section-gap-md blog-section">
      <div className="container-fluid">
        <div className="section-title section-center mb-40">
          <span className="section-title__subtitle mb-10">Our Blog Post</span>
          <h2>Our Latest <span>News & Update</span></h2>
          <p className="tx-gray"> To address this, the most definitive and audacious Commitment to end child marriage was made with the launch of Child Marriage Free India campaign.</p>
        </div>
        <Swiper
          loop={true}
          spaceBetween={25}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 1,
            },
            1000: {
              slidesPerView: 3,
            },
          }}

          className="swiper-container"
        >
          <div className="swiper-wrapper">
            {blogsdata?.map((value, index) => (
              <SwiperSlide key={index} className="swiper-slide">
                <div className="edu-blog blog-style-1">
                  <div className="thumbnail">
                    <a href={`/blogs/${value?.blog_slug}`}>
                      <img src={value.blog_image !=null ? blogImgUrl + value.blog_image : constant.DEFAULT_IMAGE} alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <h2 className="title">
                      <a href={`/blogs/${value?.blog_slug}`}>{value.blog_name}</a>
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: value.blog_short_description }} />
                    <a href={`/blogs/${value?.blog_slug}`} className="cleenhearts-btn cleenhearts-btn--border">
                      <div className="cleenhearts-btn__icon-box">
                        <div className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"><i className="ri-arrow-right-double-fill"></i></span></div>
                      </div>
                      <span className="cleenhearts-btn__text">read more</span>
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </section>
  );
}

export default HomeBlogs;
