import React from 'react'

function HomeAbout() {
  return (
    <section className='section-gap-md aboutus mtx-center' style={{background:'url(/assets/images/inspiring-bg-1-1.png)'}}>
    <div className='container-fluid'>
      <div className='row justify-content-between align-items-center'>
        <div className='col-lg-6'>
        <div className="section-title">
        <span className="section-title__subtitle mb-10">Why Choose Us</span>
          <h2>Inspiring and Helping for Better  <span>Lifestyle</span></h2>
         <h5>Giving help To Those Who Need It.</h5>
        </div>
        <p>Society for Pragati Bharat is dedicated to the development of the deprived people, backward and
poor rural women, child labour, victims of trafficking and violence Health, Sanitation and
Nutrition, Natural Resource Management, Livelihood and Entrepreneurship, Access to Justice
and Empowerment, Strengthening the community institutions and education. In district
Lalitpur, Mahoba, Jabalpur.</p>
        <ul className='features-list mt-20'>
          <li>Health, Sanitation and Nutrition</li>
          <li>Natural Resource Management</li>
          <li>Livelihood and Entrepreneurship</li>
          <li>Strengthening the Community Institutions</li>
          <li>Education</li>
          <li>Access to Justice and Empowerment</li>

        </ul>
        <a href="/about-us" className="cleenhearts-btn mt-20 mb-20">
                          <div className="cleenhearts-btn__icon-box">
                              <div className="cleenhearts-btn__icon-box__inner"><span className="icon-donate"><i className="ri-arrow-right-double-fill"></i></span></div>
                          </div>
                          <span className="cleenhearts-btn__text">Read More</span>
                      </a>
        </div>
        <div className='col-lg-6'>
          <div className='inspiring-one__image'>
            <div className='inspiring-one__image__inner inspiring-one__image__inner--one'>
              <img src='/assets/images/ins1.jpg'></img>
            </div>
            <div className='inspiring-one__image__inner inspiring-one__image__inner--two'>
              <img src='/assets/images/ins2.jpg'></img>
            </div>
          </div>
        </div>
      </div>
      </div>  
      <div className='inspiring-one__shapes'>
        <div className='inspiring-one__shape inspiring-one__shape--one'></div>
        <div className="inspiring-one__shape inspiring-one__shape--two"></div>
      </div>
    </section> 
  )
}

export default HomeAbout