// const API_URL = "https://codexotech.in/projects/pragatibharat/csadmin/api/"
const API_URL = "https://pragatibharat.org/csadmin/api/"


const BASE_URL =   ""    
const FRONT_URL =   ""    
const DEFAULT_IMAGE="/assets/images/defaultimage.png"

export default { API_URL,BASE_URL,FRONT_URL,DEFAULT_IMAGE };

