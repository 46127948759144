import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import HomeBlogs from '../../Components/Elements/home_blog'
import OurProgrammes from '../../Components/Elements/our_programmes'
import HomePartner from '../../Components/Elements/home_partner'
import HomeTestimonials from '../../Components/Elements/home_testimonials'
import HomeSlider from '../../Components/Elements/home_slider'
import HomeAbout from '../../Components/Elements/home_about'
import HomeImpactStories from '../../Components/Elements/home_impact_stories'

function Home() {
return (
<>
<Header/>
<HomeSlider/>
<HomeImpactStories/>
<OurProgrammes/>
<HomeTestimonials/>
<HomeAbout/> 
<HomePartner/>
<section className='section-gap-md bgs-cover' style={{background:'url(/assets/images/bg1.bmp)'}}>
<div className="container-fluid">
        <div className="section-title section-center mb-40">
          <h2 className='tx-white'>Save Life And Make A Positive  <span>Impact</span></h2>
          <p className="tx-white">Awareness for elimination of child labour, child marriage , child sexual abuse, child trafficking to Anganwari worker and Adolescent group</p>
        </div>
        <a href="/about-us" className="cleenhearts-btn cleenhearts-btn--border">
                      <div className="cleenhearts-btn__icon-box">
                        <div className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"><i className="ri-arrow-right-double-fill"></i></span></div>
                      </div>
                      <span className="cleenhearts-btn__text tx-white">read more</span>
                    </a>
        </div>
</section>
<HomeBlogs/>
<Footer/>
</>
)
}

export default Home