import React, { useEffect, useRef, useState } from "react";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useParams } from "react-router";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";

function OurProjectDetails() {
  const{slug}= useParams()
  const didMountRef = useRef(true);
  const [projectList, setProjectList] = useState([]);
  const [projectImgUrl, setProjectImgUrl] = useState("");
  useEffect(() => {
    if (didMountRef.current) {
      storiesdata();
    }
    didMountRef.current = false;
  }, []);
  const storiesdata = () => {

    const dataString={
      "project_slug" : slug
    }
    ApiService.postData("project-details",dataString).then((res) => {
      console.log(res);
      if (res.status === "success") {
        setProjectList(res.projectDetails);
        setProjectImgUrl(res.project_url);

      }
    });
  };
  return (
    <>
     <Header/>


     <div className="subheader">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <h1>{projectList?.project_name}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            {/* <li className="breadcrumb-item"><a href="/impact-stories">Our Projects</a></li> */}
                            <li className="breadcrumb-item active" aria-current="page">{projectList?.project_name}</li>
                        </ol>
                        </nav>
                </div>
            </div>
        </div>
        <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div>
    </div>

    <div dangerouslySetInnerHTML={{__html:projectList.project_desc}}></div>
     {/* <section className="section-gap-md">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">

    <img src={projectList.project_image!=null ? projectImgUrl + projectList.project_image : constant.DEFAULT_IMAGE} alt={projectList.project_name}/>
    <h1>{projectList.project_name}</h1>

    </div>
          </div>
          </div>

        </section> */}

     <Footer/>
    </>
  )
}

export default OurProjectDetails