import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function DonateNow() {
  return (
    <>
      <Header />
      <div className="subheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>Donate Now</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Donate Now</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="subheader-shape1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image" />
        </div>
        <div className="subheader-shape2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image" />
        </div>
        <div className="subheader-line1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image" />
        </div>
        <div className="subheader-line2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image" />
        </div>
      </div>
      <Footer />

    </>

  )
}

export default DonateNow