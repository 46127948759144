import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import moment from "moment";
import { useParams } from "react-router";

function Blogs() {
  const didMountRef = useRef(true);
  const { slug } = useParams()
  const [blogList, setBlogList] = useState([]);
  const [blogImgUrl, setBlogImgUrl] = useState("");
  const [pageData, setPageData] = useState({})
  const [headerImageUrl, setHeaderImageUrl] = useState('')
  useEffect(() => {
    if (didMountRef.current) {
      getBlogList();
      getPagesData()
    }
    didMountRef.current = false;
  });
  const getPagesData = () => {
    const pageContent = {
      slug: "blogs",
    };

    ApiService.postData("page-data", pageContent).then((res) => {
      if (res.status == "success") {
        setPageData(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
    });
  };
  const getBlogList = () => {
    ApiService.fetchData("blog-list").then((res) => {
      console.log(res);
      if ((res.status = "success")) {
        setBlogList(res.blogList);
        setBlogImgUrl(res.blog_url);
      }
    });
  };

  return (
    <>
      <Header />
      <>
        <div className="subheader">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1>{pageData?.page_name}</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{pageData?.page_name}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="subheader-shape1">
            <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image" />
          </div>
          <div className="subheader-shape2">
            <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image" />
          </div>
          <div className="subheader-line1">
            <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image" />
          </div>
          <div className="subheader-line2">
            <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image" />
          </div>
        </div>

        <section className="section-gap-md">
          <div className="container">
            <div className="row">
              {blogList?.map((value, index) => {
                return (
                  <>
                    <div className="col-lg-4" key={index}>
                      <div className="edu-blog blog-style-1">
                        <div className="inner">
                          <div className="thumbnail">
                            <a href={`/blogs/${value?.blog_slug}`}>
                              <img src={blogImgUrl + value.blog_image} />
                            </a>
                          </div>
                          <div className="content">
                            <h5 className="title">
                              <a href={`/blogs/${value?.blog_slug}`}>
                                {value.blog_name}
                              </a>
                            </h5>
                            <ul className="blog-meta p-0" style={{ listStyle: "none" }}>
                              <li>
                                <i className="ri-calendar-2-line"></i>
                                {moment(value.created_at).format('MMM D, YYYY')}
                              </li>
                            </ul>
                            <p>{value.blog_short_description}</p>
                            <a href={`/blogs/${value?.blog_slug}`} className="cleenhearts-btn cleenhearts-btn--border">
                              <div className="cleenhearts-btn__icon-box">
                                <div className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"><i className="ri-arrow-right-double-fill"></i></span></div>
                              </div>
                              <span className="cleenhearts-btn__text">read more</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  );
}

export default Blogs;
