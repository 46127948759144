import React, { useEffect, useRef, useState } from 'react'
import HeaderMenu from '../Elements/header_menu'
import { ApiService } from '../Services/apiservices';
import { BrowserView, MobileView } from 'react-device-detect';
import { Modal } from 'react-bootstrap';
import MenuModal from '../Elements/Modals/menu_modal';

function Header() {
    const didMountRef = useRef(true);
    const [settingData, setSettingData] = useState({});
    const [settingImagePath, setSettingImagePath] = useState("");
    useEffect(() => {
        if (didMountRef.current) {
            getSettingsData();
        }
        didMountRef.current = false;
    });
    const getSettingsData = () => {
        ApiService.fetchData("setting-data").then((res) => {
            if (res.status === "success") {
                setSettingData(res.sitesettings);
                setSettingImagePath(res.setting_image_path)

            }
        });
    };


    const handleShowMenuCart=()=>setShowMenuCart(true);
    const [showMenuCart,setShowMenuCart]=useState(false);
   
    const handleChildMenuCartData = (status) => {
        setShowMenuCart(status);
      };
      const handleMenuClose = () => {
        setShowMenuCart(false);
      };

    return (
        <>
            <BrowserView>
                <div className='headertop'>
                    <div className='container-fluid'>
                        <div className='row align-items-center'>
                            <div className='col-lg-9'>
                                <div className='prgaticcorner'>
                                    <div className='prgatic-corner'>Pragati Corner</div>
                                    <div className='prgatic-corner-content'>
                                        <marquee>{settingData.header_top}</marquee>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <ul className='topsocial'>
                                    <li>Follow Us On -</li>
                                    {settingData.facebook_url != null ? (
                                        <li><a href={settingData.facebook_url} target="new"><i className="ri-facebook-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.twitter_url != null ? (
                                        <li><a href={settingData.twitter_url} target="new"><i className="ri-twitter-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.linkedin_url != null ? (
                                        <li><a href={settingData.linkedin_url} target="new"><i className="ri-linkedin-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.youtube_url != null ? (
                                        <li><a href={settingData.youtube_url} target="new"><i className="ri-youtube-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.instagram_url != null ? (
                                        <li><a href={settingData.instagram_url} target="new"><i className="ri-instagram-line"></i></a></li>
                                    ) : (
                                        ""
                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <header className="header">
                    <div className="container-fluid">
                        <div className="header-left">
                            <a className="logo" href="/"><img src="/assets/images/logo.png" /></a>
                        </div>
                        <HeaderMenu />
                        <div className="header-right justify-content-end">
                            <a href="/donate-now" className="cleenhearts-btn">
                                <div className="cleenhearts-btn__icon-box">
                                    <div className="cleenhearts-btn__icon-box__inner"><span className="icon-donate"><i className="ri-service-line mr-5"></i></span></div>
                                </div>
                                <span className="cleenhearts-btn__text">Donate Now</span>
                            </a>
                        </div>
                    </div>
                </header>
            </BrowserView>
            <MobileView>
                <div className='headertop'>
                    <div className='container-fluid'>
                        <div className='row align-items-center'>
                            <div className='col-lg-9'>
                                <div className='prgaticcorner'>
                                    <div className='prgatic-corner-content'></div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <ul className='topsocial'>
                                    <li>Follow Us On -</li>
                                    {settingData.facebook_url != null ? (
                                        <li><a href={settingData.facebook_url} target="new"><i className="ri-facebook-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.twitter_url != null ? (
                                        <li><a href={settingData.twitter_url} target="new"><i className="ri-twitter-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.linkedin_url != null ? (
                                        <li><a href={settingData.linkedin_url} target="new"><i className="ri-linkedin-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.youtube_url != null ? (
                                        <li><a href={settingData.youtube_url} target="new"><i className="ri-youtube-fill"></i></a></li>
                                    ) : (
                                        ""
                                    )}
                                    {settingData.instagram_url != null ? (
                                        <li><a href={settingData.instagram_url} target="new"><i className="ri-instagram-line"></i></a></li>
                                    ) : (
                                        ""
                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <header className='mheader'>
                    <div className='mheader-left'>
                        <div className='sidenav-trigger' onClick={handleShowMenuCart}>
                            <i className="ri-menu-3-line ri-xl"></i>
                        </div>
                        <a href='/' className='mlogo'>
                            <img src="/assets/images/logo.png" />
                        </a>
                    </div>
                    <div className='mheader-right'>
                        <a href="/donate-now" className="cleenhearts-btn-sm">
                            <div className="cleenhearts-btn__icon-box-sm">
                                <div className="cleenhearts-btn__icon-box__inner-sm"><span className="icon-donate"><i className="ri-service-line"></i></span></div>
                            </div>
                            <span className="cleenhearts-btn__text-sm">Donate Now</span>
                        </a>
                    </div>
                </header>
            </MobileView>
              <Modal show={showMenuCart}
              onHide={() => handleChildMenuCartData(false)}
        className="left menu-modal">
                {
                    showMenuCart && (
                        <MenuModal showMenuCart={{show:showMenuCart , setShow:setShowMenuCart}}
                            handleChildMenuCartData={handleChildMenuCartData}
                        />
                    ) 
                }
              </Modal>                          
        </>
    )
}

export default Header