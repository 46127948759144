import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Containers/Home';
import Blogs from './Containers/Blogs';
import BlogDetails from './Containers/BlogDetails';
import Pages from './Containers/Pages';
import ImpactStories from './Containers/ImpactStories';
import ImpactStoriesDetail from './Containers/ImpactStoriesDetails';
import { ToastContainer } from 'react-toastify';
import ContactUs from './Containers/ContactUs';
import Programs from './Containers/Programs';
import ImageGallery from './Containers/Gallery/image-gallery';
import VideoGallery from './Containers/Gallery/video-gallery';
import Events from './Containers/Events';
import EventDetails from './Containers/EventsDetail';
import OurProjects from './Containers/OurProjects';
import OurProjectDetails from './Containers/OurProjectDetails';
import DonateNow from './Containers/DonateNow';
import NewsMedia from './Containers/NewsMedia';
import Resources from './Containers/Resources';


function App() {
  return (
    <div className="App">
          <ToastContainer />
      <Router>
        <Routes>
          <Route exact path='/' activeClassName="active" element={<Home/>}/>
          <Route exact path='/blogs' activeClassName="active" element={<Blogs/>}/>
          <Route exact path='/blogs/:slug' activeClassName="active" element={<BlogDetails/>}/>
          <Route exact path='/:slug' activeClassName="active" element={<Pages />} />
          <Route exact path='/image-gallery' activeClassName="active" element={<ImageGallery/>} />
          <Route exact path='/video-gallery' activeClassName="active" element={<VideoGallery/>} />
          <Route exact path='/impact-stories' activeClassName="active" element={<ImpactStories />} />
          <Route exact path='/news-events' activeClassName="active" element={<Events />} />
          <Route exact path='/impact-stories/:slug' activeClassName="active" element={<ImpactStoriesDetail />} />
          <Route exact path='/contact-us' activeClassName="active" element={<ContactUs />} />
          <Route exact path='/our-projects' activeClassName="active" element={<OurProjects />} />
          <Route exact path='/our-projects/:slug' activeClassName="active" element={<OurProjectDetails />} />
          <Route exact path='/our-programs/:slug' activeClassName="active" element={<Programs />} />
          <Route exact path='/news-events/:slug' activeClassName="active" element={<EventDetails />} />
          <Route exact path='/donate-now' activeClassName="active" element={<DonateNow />} />
          <Route exact path='/news-media' activeClassName="active" element={<NewsMedia />} />
          <Route exact path='/resources/:slug' activeClassName="active" element={<Resources />} />








        </Routes>
      </Router>
    </div>
  );
}

export default App;
