import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert, Button } from 'react-bootstrap';
function ContactUs() {

  const didMountRef = useRef(true)
  const [pageData, setPageData] = useState({})
  const [headerImageUrl, setHeaderImageUrl] = useState('')
  const [settingData, setSettingData] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [contactInput, setContactInput] = useState({
    contact_name: "",
    contact_email: "",
    contact_message: "",
    contact_mobile: "",
  });
  useEffect(() => {
    if (didMountRef.current) {
      getPagesData()
      getSettingData()
    }
    didMountRef.current = false
  })
  const resetContactForm = () => {
    setContactInput({
        contact_name: "",
        contact_email: "",
        contact_mobile: "",
        contact_message: "",
    });
};
  const getPagesData = () => {
    const pageContent = {
      slug: "contact-us",
    };

    ApiService.postData("page-data", pageContent).then((res) => {
      if (res.status == "success") {
        setPageData(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
    });
  };
  function isValidEmail(contact_email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/.test(contact_email);
  }
  function isValidMobile(contact_mobile) {
    return /^[6-9]\d{9}$/.test(contact_mobile);
  }
  const contactusProcess = () => {
    if (contactInput.contact_name === "") {
      toast.error("Please Enter Name")
      return false;
    }
    if (contactInput.contact_email === "") {
      toast.error("Please Enter Email")
      return false;
    }
    if (!isValidEmail(contactInput.contact_email)) {
      toast.error("Please Enter Valid Email");
      return false;
    }
    if ((contactInput.contact_mobile === "")) {
      toast.error("Please Enter Number")
      return false;
    }
    if (!isValidMobile(contactInput.contact_mobile)) {
      toast.error("Please Enter 10-digit Number")
      return false;
    }

    ApiService.postData("contact-us-process", contactInput).then((res) => {
      if (res.status === "success") {
        setSuccessMessage(res.message)
        resetContactForm();
        setTimeout(() => {
          setSuccessMessage();
        }, 2000);

      }
    })
  }

  const handleContact = (e) => {
    const { name, value } = e.target;
    setContactInput({ ...contactInput, [name]: value })
  }
   
   const getSettingData = () =>{
    ApiService.fetchData("setting-data").then((res)=>{
      if(res.status === "success"){
        setSettingData(res.sitesettings)
      }

    })



   }

  return (

    <>
      <Header />
      <div className="">
        {/* <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <h1>{pageData?.page_name}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{pageData?.page_name}</li>
                        </ol>
                        </nav>
                </div>
            </div>
        </div> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.0530799106664!2d78.40001498571844!3d24.69070199542366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3979bbad0178666f%3A0x640213ccca89d498!2sSociety%20for%20Pragati%20Bharat!5e0!3m2!1sen!2sin!4v1710574400877!5m2!1sen!2sin"
          width="100%"
          height="400"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        >

        </iframe>
        {/* <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div> */}
      </div>
      <section className="section-gap-md">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="section-title mb-4">
                {/* <span className="pre-title">Contact us</span> */}
                <h2 className="">Get In Touch With Us</h2>
                <p className="mb-0 mt-0">Complete our online enquiry form located below and we will get back to you as soon as possible.</p>
              </div>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              <div className="row g-2">
                <div className="col-lg-12">
                  <div className="form-group mb-3"><label>Name</label>
                    <input type="text" className='form-control '
                      name="contact_name"
                      value={contactInput.contact_name}
                      onChange={(e) => {
                        handleContact(e)
                      }}
                    /></div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3"><label>Email Address</label>
                    <input type="email"className='form-control '
                      name="contact_email"
                      value={contactInput.contact_email}
                      onChange={(e) => {
                        handleContact(e)
                      }}
                    /></div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3"><label>Mobile Number</label>
                    <input type="number"
                      name="contact_mobile"className='form-control '
                      value={contactInput.contact_mobile}
                      onChange={(e) => {
                        handleContact(e)
                      }}
                    /></div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3"><label>Message</label>
                    <textarea
                      name="contact_message"className='form-control '
                      value={contactInput.contact_message}
                      onChange={(e) => {
                        handleContact(e);
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-6">

                <button type="button" class="btn btn-primary btn-medium btn btn-primary" onClick={contactusProcess}>Submit</button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-address-section">
                <div className="section-title mb-20">
                  <h2>Contact Info</h2>
                </div>
                <h5>Pragati Bharat</h5>
                <div className="address-contact">
                  <ul>
                    <li>
                    <a href="https://maps.app.goo.gl/HuBSQPufMw2KDGcM6" target="new">
                    <i className="ri-map-pin-2-line mr-5"></i><span>{settingData.address}</span></a></li>
                    <li><a href={"mailto:" + settingData.admin_support_email}><i className="ri-mail-line mr-5"></i><span>{settingData.admin_support_email}</span></a></li>
                    <li><a href={"tel:" + settingData.admin_mobile}><i className="ri-phone-line mr-5"></i><span>{settingData.admin_mobile}</span></a></li>
                    <li><a href={"tel:" + settingData.admin_support_mobile}><i className="ri-phone-line mr-5"></i><span>{settingData.admin_support_mobile}</span></a></li>
                   
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <Footer />
    </>

  )
}

export default ContactUs