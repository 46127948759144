import React, { useEffect, useRef, useState } from "react";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useParams } from "react-router";
import { ApiService } from "../../Components/Services/apiservices";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
function ImageGallery() {
  const didMountRef = useRef(true);
  const { slug } = useParams()
  const [galleryData, setGalleryData] = useState([]);
  const [galleryImgUrl, setGalleryImgUrl] = useState("");
  const [pageData, setPageData] = useState({})
  const [headerImageUrl, setHeaderImageUrl] = useState('')
  useEffect(() => {
    if (didMountRef.current) {
      getImagegallery();
      getPagesData()
    }
    didMountRef.current = false;
  });
  const getPagesData = () => {
    const pageContent = {
      slug: "image-gallery",
    };

    ApiService.postData("page-data", pageContent).then((res) => {
      if (res.status == "success") {
        setPageData(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
    });
  };
  const getImagegallery = () => {
    ApiService.fetchData("gallery-image").then((res) => {
      console.log(res);
      if ((res.status = "success")) {
        setGalleryData(res.galleryData);
        setGalleryImgUrl(res.gallery_url);
      }
    });
  };
  return (
    <>
      <Header />
      <div className="subheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>{pageData?.page_name}</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{pageData?.page_name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="subheader-shape1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image" />
        </div>
        <div className="subheader-shape2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image" />
        </div>
        <div className="subheader-line1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image" />
        </div>
        <div className="subheader-line2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image" />
        </div>
      </div>
      <section className="section-gap-md">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="galarybox">
                <LightGallery
                  plugins={[lgThumbnail]}>
                  {galleryData.map((value, index) => (
                    <a href={galleryImgUrl + value.gallery_image} key={index}>
                      <img
                        src={galleryImgUrl + value.gallery_image}
                        alt=""
                        width={"100%"}
                        height={"385px"}
                      />
                      <p className="text-center" style={{ color: "#C1272D" }}>{value.gallery_title}</p>
                    </a>
                  ))}
                </LightGallery>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ImageGallery