import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { ApiService } from '../Services/apiservices';
import constant from '../Services/constant';
function HomeTestimonials() {

  const [testimonialdata, settestimonialdata] = useState([]);
  const [imagepath, setImagepath] = useState("");
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getTestimonialData();

    }
    didMountRef.current = false;
  });
  const getTestimonialData = () => {

    ApiService.fetchData("featured-testimonial").then((response) => {
      if (response.status == "success") {
        settestimonialdata(response?.featuredtestimonial);
        setImagepath(response?.testimonial_img_path);
      }
    });
  };


  const TestimonialsCarouselOptions = {
    loop: true,
    spaceBetween: 0,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
      1000: {
        slidesPerView: 1,
      },
    },
  };


  return (
    <>
      <section className='section-gap-md counter pt-0'>
        <div className='container-fluid'>
          <div className='row g-3'>
            <div className='col'>
              <div className='edu-counterup bprimary-color'>
                <h2 className='count-number primary-color'>2.1 Million</h2>
                <h6>Lives Impacted </h6>
              </div>
            </div>
            <div className='col'>
              <div className='edu-counterup bsecondary-color'>
                <h2 className='count-number secondary-color'>177,547</h2>
                <h6>Women Empowered </h6>
              </div>
            </div>
            <div className='col'>
              <div className='edu-counterup bextra02-color'>
                <h2 className='count-number extra02-color'>6.3 M USD</h2>
                <h6>Credit Disbursed</h6>
              </div>
            </div>
            <div className='col'>
              <div className='edu-counterup bextra05-color'>
                <h2 className='count-number extra05-color'>1.7 M USD</h2>
                <h6>Savings Mobilised</h6>
              </div>
            </div>
            <div className='col'>
              <div className='edu-counterup bextra03-color'>
                <h2 className='count-number extra03-color'>19.65-Lakh</h2>
                <h6>Happiness Kits </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-gap-md testimonials pt-0'>
        <div className="container-fluid">
          <div className="section-title section-center mb-40">
            <span className="section-title__subtitle mb-10">Testimonials</span>
            <h2>What People Say  <span>About Us</span></h2>
            <p className="tx-gray">The organization is working on the following issue of Child Marriage, Child Trafficking, Child Labour and Child Sexual Abuse. </p>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>

              <Swiper
                {...TestimonialsCarouselOptions}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 3000,
                }}
                modules={[Autoplay, Pagination]}
              >
                {testimonialdata.map((list, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className='testimonial_slide_box'>
                        <div className="rating mb-10">

                          {Array.from({ length: list.testimonial_rating }, (_, i) => (
                            <span key={i}><i className="ri-star-fill"></i></span>
                          ))}
                        </div>
                        <p className="review" dangerouslySetInnerHTML={{ __html: list?.testimonial_desc }}>
                        </p>
                        <div className='testimonial_img mb-10'>
                          <img src={list.testimonial_image != null
                            ? imagepath + list.testimonial_image
                            : constant.DEFAULT_IMAGE
                          }></img>
                        </div>
                        <h5>{list?.testimonial_name}</h5>
                        <span className="designation">{list?.testimonial_designation}</span>
                      </div>
                    </SwiperSlide>

                  );
                })}


              </Swiper>
            </div>
          </div>
        </div>

      </section>


    </>
  )
}

export default HomeTestimonials