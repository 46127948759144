import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function ImpactStories() {
  const didMountRef = useRef(true);
  const [storiesList, setStoriesList] = useState([]);
  const [storiesImgUrl, setStoriesImgUrl] = useState("");
  const [pageData, setPageData] = useState({})
  const [headerImageUrl, setHeaderImageUrl] = useState('')
  useEffect(() => {
    if (didMountRef.current) {
      getStoriesData();
      getPagesData()
    }
    didMountRef.current = false;
  }, []);
  const getPagesData = () => {
    const pageContent = {
      slug: "impact-stories",
    };

    ApiService.postData("page-data", pageContent).then((res) => {
      if (res.status == "success") {
        setPageData(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
    });
  };
  const getStoriesData = () => {
    ApiService.fetchData("stories-list").then((res) => {
      console.log(res);
      if ((res.status = "success")) {
        setStoriesList(res.storiesList);
        setStoriesImgUrl(res.stories_url);
      }
    });
  };
  return (

    <>
      <Header />
      <div className="subheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>{pageData?.page_name}</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{pageData?.page_name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div>
      </div>


      <section className="section-gap-md">
        <div className="container">
          <div className="row">
            {storiesList?.map((value, index) => {
              return (
                <>
                  <div className="col-lg-4 mb-5" key={index}>
                    <div className="edu-blog blog-style-1">
                      <div className="thumbnail">
                        <a href={`/impact-stories/${value?.stories_slug}`}>
                          <img src={storiesImgUrl + value.stories_image} className="img-fluid " />
                        </a>
                      </div>
                      <div className="content">
                        <h5 className="title">
                          <a href={`/impact-stories/${value?.stories_slug}`}>
                            {value.stories_name}
                          </a>
                        </h5>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: value.stories_short_description,
                          }}
                        ></p>
                        <a href={`/impact-stories/${value?.stories_slug}`} className="cleenhearts-btn cleenhearts-btn--border">
                          <div className="cleenhearts-btn__icon-box">
                            <div className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"><i className="ri-arrow-right-double-fill"></i></span></div>
                          </div>
                          <span className="cleenhearts-btn__text">read more</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  
                </>
              );
            })}

          </div>
        </div>
      </section>
      <Footer />
    </>

  )
}

export default ImpactStories