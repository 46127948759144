import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../Components/Services/apiservices';
import { useParams } from 'react-router';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

function Pages() {
    const { slug } = useParams();
    const [pageData, setPageData] = useState(null);
  const [pageContent, setPageContent] = useState("");

    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            getPagesData();
            didMountRef.current = false;
        }
    }, [slug]); 

    const getPagesData = () => {
        const dataString = {
            slug: slug,
        };
        ApiService.postData('page-data', dataString).then((res) => {
                if (res.status === 'success') {
                    setPageData(res.data)
                    setPageContent(res.data.page_content);
                }
            })
            .catch((error) => {
                console.error('Error fetching page data:', error);
            });
    };

    return (
        <>
        <Header/>
        <div className="subheader">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <h1>{pageData?.page_name}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{pageData?.page_name}</li>
                        </ol>
                        </nav>
                </div>
            </div>
        </div>
        <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div>
    </div>
    {pageContent != null ? (
          <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
        ) : (
          ""
        )}
           <Footer/> 

        </>
    );
}

export default Pages;
