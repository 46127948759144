import React, { useEffect, useRef, useState } from "react";

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from "react-router";
import constant from "../../Components/Services/constant";
import moment from "moment";

function BlogDetails() {
  const { slug } = useParams()
  const didMountRef = useRef(true);
  const [blogDetail, setBlogDetail] = useState({});
  const [latestBlogDetail, setLatestBlogDetail] = useState([]);
  const [blogImageUrl, setBlogImageUrl] = useState({});
  const [latestBlogImageUrl, setlatestBlogImageUrl] = useState('');
  useEffect(() => {
    if (didMountRef.current) {
      Blogdata();
    }
    didMountRef.current = false;
  }, []);
  const Blogdata = () => {

    const dataString = {
      "blog_slug": slug
    }
    ApiService.postData("blog-details", dataString).then((res) => {
      console.log(res);
      if (res.status == "success") {
        setBlogDetail(res.blogDetails)
        setBlogImageUrl(res.blog_url)
      }
    });

    ApiService.fetchData('blog-list-sidebar-data').then((res) => {
      if (res.status == "success") {
        setLatestBlogDetail(res?.latestblogdata);
        setlatestBlogImageUrl(res?.blog_url)

      }
    })
  };
  return (
    <>
      <Header />
      <div className="subheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>{blogDetail?.blog_name}</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{blogDetail?.blog_name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="subheader-shape1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image" />
        </div>
        <div className="subheader-shape2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image" />
        </div>
        <div className="subheader-line1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image" />
        </div>
        <div className="subheader-line2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image" />
        </div>
      </div>


      <section className="section-gap-md">
        <div className="container">
          <div className="row">
            <div className="col-lg-8" >
              <div className="edu-blog blog-style-1">
                <div className="inner">
                  <div className="thumbnail">
                    <img className="img-fluid" style={{ height: "500px" }} src={blogDetail?.blog_image != null ? blogImageUrl + blogDetail?.blog_image : constant.DEFAULT_IMAGE} alt='blog_image' />

                  </div>
                  <div className="content">
                    <h5 className="title">
                      {blogDetail?.blog_name}

                    </h5>
                    <ul className="blog-meta p-0" style={{ listStyle: "none" }}>
                      <li>
                        <i className="ri-calendar-2-line"></i>
                        {moment(blogDetail.created_at).format('MMM D, YYYY')}
                      </li>
                    </ul>
                    <p dangerouslySetInnerHTML={{ __html: blogDetail?.blog_desc }}></p>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebarbox">
                <h2 className="title">Latest Blog</h2>
                {latestBlogDetail?.map((value, index) => {
                  return <>
                    <div className="ltblog" key={index}>
                      <div className="ltblog-img">
                      <a href={`/blogs/${value?.blog_slug}`}>
                        <img src={value?.blog_image != null ? latestBlogImageUrl + value?.blog_image : constant.DEFAULT_IMAGE} alt="Blog Images" />
                        </a>
                      </div>
                      <div className="ltblog-content">
                        <div className="listmeta">
                          <ul>
                            <li><i className="icon-27"></i>
                              {moment(value.created_at).format('MMM D, YYYY')}
                            </li>
                          </ul>
                        </div>
                        <h2 className="title">
                          <a href={`/blogs/${value?.blog_slug}`}>{value?.blog_name}</a>
                        </h2>
                      </div>
                    </div>
                  </>
                })}
              </div>
            </div>

          </div>
        </div>

      </section>
      <Footer />

    </>
  )
}

export default BlogDetails