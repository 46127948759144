import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { useParams } from "react-router";
import constant from '../../Components/Services/constant';

function EventDetails() {
    const{slug}= useParams()
    const [eventData, setEventData] = useState({});
    const [eventsUrl, setEventsUrl] = useState("");
    const didMountRef = useRef(true);

    useEffect(()=>{
if(didMountRef.current){

    eventdetails()
}
didMountRef.current = false;
}, []);


const eventdetails = () =>{
        
    const dataString = {

        event_slug :  slug

    }

ApiService.postData("event-details",dataString).then((res)=>{
    setEventData(res.eventDetails)
    setEventsUrl(res.events_url)
})
}


  return (
    <>
    <Header/>
        <div className="subheader">
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-12">
                <h1>{eventData?.event_name}</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/impact-stories">Impact Stories</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{eventData?.event_name}</li>
                    </ol>
                    </nav>
            </div>
        </div>
    </div>
    <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div>
</div>

 <section className="section-gap-md">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">

<img src={eventData.event_image!=null ? eventsUrl + eventData.event_image : constant.DEFAULT_IMAGE} alt={eventData.event_name}/>
<h1>{eventData.event_name}</h1>
<p dangerouslySetInnerHTML={{__html:eventData.event_desc}}></p>
</div>
      </div>
      </div>

    </section>
    <Footer/>
    
    </>

  )
}

export default EventDetails