import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from '../../Services/apiservices';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

function MenuModal({ showMenuCart, handleChildMenuCartData }) {
    const { show, setShow } = showMenuCart;
    const [menuData, setMenuData] = useState([]);
    const didMountRef = useRef(true);
    const [menuList, setMenuList] = useState([]);
    const [menuUrl, setMenuUrl] = useState("");

    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(-1);


    useEffect(() => {
        if (didMountRef.current) {
            getMenuData();
        }
        didMountRef.current = false;
    });
    const handleClose = () => {
        setShow(false);
        handleChildMenuCartData(false);
    };

    const handleShow = () => {
        setShow(true);
        handleChildMenuCartData(true);
    };
    const getMenuData = () => {
        ApiService.fetchData("menu-list").then((res) => {
            console.log(res);
            if (res.status == "success") {
                setMenuList(res?.data);
                setMenuUrl(res?.menuUrl);
            }
        });
    };
    const toggleSubMenu = (index) => {
        setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    return (
        <>
            {menuList?.length > 0 ? (
                <>
                    <div className="p-2">
                        <a href="/" className="mlogo">
                            <img
                                src="/assets/images/logo.png"
                                width={125}
                                height={39}
                            ></img>
                        </a>
                    </div>
                    <hr style={{ marginTop: "0" }}></hr>
                    <div className="sidemenu">
                        <ul>
                            {menuList.map((parent, index) => {
                                const hasChildren =
                                    parent.children && parent.children.length > 0;
                                const isSubMenuOpen = openSubMenuIndex === index;

                                return (
                                    <li key={index} className="sub-menu">
                                        <div className="sub-menu-inner">
                                            <a href={parent.url}>{parent.menu_name}</a>

                                            {hasChildren && (
                                                <div
                                                    className="right"
                                                    onClick={() => toggleSubMenu(index)}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={isSubMenuOpen ? faCaretUp : faCaretDown}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        {hasChildren && isSubMenuOpen && (
                                            <ul>
                                                {parent.children.map((childItem, childIndex) => (
                                                    <li key={childIndex} className="sub-menu">
                                                        <div className="sub-menu-inner">
                                                            <a href={childItem.url}>{childItem.menu_name}</a>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            ) : (
                ""
            )}
        </>
    );
}

export default MenuModal;