import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ApiService } from '../Services/apiservices';
import constant from '../Services/constant';
function OurProgrammes() {

  const didiMountRef = useRef(true);

  const [programList, setProgramList] = useState([])
  const [programImgUrl, setProgramImgUrl] = useState("")

  useEffect(() => {
    if (didiMountRef.current) {
      getProgramList()

    }
    didiMountRef.current = false
  })

  const getProgramList = () => {

    ApiService.fetchData("programs-list").then((res) => {
      if (res.status === "success") {
        setProgramList(res.programList)
        setProgramImgUrl(res.program_img_url)
      }
    })
  }
  const ProgramsCarouselOptions = {
    loop: true,
    spaceBetween: 25,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
      1000: {
        slidesPerView: 3,
      },
    },
  };
  return (

    <div className='ourprograms'>
      <section className='section-gap-md program-one'>
        <div className='program-one-bg' style={{ background: 'url(/assets/images/help-donate-bg-1-1.jpg)' }}></div>
        <div className='container-fluid'>
          <div className="section-title section-center">
            <span className="section-title__subtitle mb-10 tx-white">Programmes</span>
            <h2 className='tx-white'>Our <span>Programmes</span></h2>
            <p className='tx-white'>Our thematic programmes are designed to improve the living conditions of village
              communities and make villages a better place to live.</p>
          </div>
        </div>
      </section>
      <section className='programslides'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <Swiper
                {...ProgramsCarouselOptions}
              >
                {programList?.map((list, index) => (

                  <SwiperSlide key={index}>
                    <div className='programbox'>
                      <div className='programbox-img'>
                        <a href={`/our-programs/${list.program_slug}`}>
                          <img src={list.program_image != null ? programImgUrl + list.program_image : constant.DEFAULT_IMAGE} />
                        </a>
                      </div>
                      <div className='programbox-content'>
                        <h2 className="title"><a href={`/our-programs/${list.program_slug}`}>{list.program_name}</a></h2>
                        <p className="programbox-text">{list.program_short_description}</p>
                        <a href={`/our-programs/${list.program_slug}`} className="cleenhearts-btn cleenhearts-btn--border">
                          <div className="cleenhearts-btn__icon-box">
                            <div className="cleenhearts-btn__icon-box__inner"><span className="icon-duble-arrow"><i className="ri-arrow-right-double-fill"></i></span></div>
                          </div>
                          <span className="cleenhearts-btn__text">read more</span>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>


                ))}
              </Swiper>
            </div>
          </div>
        </div>

      </section>
    </div>

  )
}

export default OurProgrammes