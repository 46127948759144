import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../Services/apiservices";
import { useLocation } from "react-router-dom";
function HeaderMenu() {
  const didMountRef = useRef(true);
  const [menuList, setMenuList] = useState([]);
  const [menuUrl, setMenuUrl] = useState("");

  useEffect(() => {
    if (didMountRef.current) {
      getMenuData();
    }
    didMountRef.current = false;
  });

  const getMenuData = () => {
    ApiService.fetchData("menu-list").then((res) => {
      console.log(res);
      if (res.status == "success") {
        setMenuList(res?.data);
        setMenuUrl(res?.menuUrl);
      }
    });
  };

  const location = useLocation();
  return (
    <>
      <div className="header-center">
        <nav className="navbar navbar-expand-lg navbar-light">
          <ul className="navbar-nav">
            {menuList?.map((items, index) => {
              return (
                <>
                  {!items?.children?.length > 0 ? (
                    <>
                      <li className="nav-item" key={index}>
                        <a
                          className={
                            location.pathname === items?.url
                              ? "nav-link active"
                              : "nav-link"
                          }
                          aria-current="page"
                          href={items?.url}
                        >
                          {items?.menu_name}
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item dropdown dropdown-hover">
                        <a
                          id="navbarDropdown"
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {items?.menu_name}
                        </a>
                        {items?.children.length > 0 ? (
                          <>
                            <ul
                              className="dropdown-menu submenu"
                              aria-labelledby="navbarDropdown"
                            >
                              {items?.children?.map((childitem, indexchild) => {
                                return (
                                  <>
                                    <li key={indexchild}>
                                      <a
                                        href={childitem?.url}
                                        className="dropdown-item"
                                      >
                                        {childitem?.menu_name}
                                      </a>
                                      {childitem?.children.length > 0 ? (
                                        <>
                                          <ul
                                            className="dropdown-menu submenu"
                                            aria-labelledby="navbarDropdown"
                                          >
                                            {childitem?.children?.map(
                                              (subchild, indexsubchild) => {
                                                // console.log(subchild,"subchild")
                                                return (
                                                  <>
                                                    <li key={indexsubchild}>
                                                      <a
                                                        href={subchild?.url}
                                                        className="dropdown-item"
                                                      >
                                                        {subchild?.menu_name}
                                                      </a>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </>
                        ) : (
                          ""
                        )}
                      </li>
                    </>
                  )}
                </>
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default HeaderMenu;
