import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import constant from '../../Components/Services/constant';

function Resources() {
  const { slug } = useParams();
  const didMountRef = useRef(true)
  const [pageData, setPageData] = useState({})
  const [headerImageUrl, setHeaderImageUrl] = useState('')
  const [imgUrl, setImgUrl] = useState('')

  const [resourcedata, setResourcedata] = useState([])

  useEffect(() => {
    if (didMountRef.current) {

      getPagesData()
      getResourceData()
    }
    didMountRef.current = false
  })

  const getPagesData = () => {
    const dataString = {
      slug: slug,

    }
    ApiService.postData("page-data", dataString).then((res) => {
      if (res.status === "success") {
        setPageData(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
    })

  }

  const getResourceData = () => {
    const dataString = {
      slug: slug,

    }


    ApiService.postData("resources", dataString).then((res) => {
      if (res.status === "success") {
        setResourcedata(res.resourcedata)
        setImgUrl(res.resource_url)
      }
    })
  }

  return (
    <>
      <Header />
      <div className="subheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>{pageData?.page_name}</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{pageData?.page_name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="subheader-shape1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image" />
        </div>
        <div className="subheader-shape2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image" />
        </div>
        <div className="subheader-line1">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image" />
        </div>
        <div className="subheader-line2">
          <img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image" />
        </div>
      </div>

      <section className="section-gap-md">
        <div className="container">
          <div className="row">
            {resourcedata.map((value, index) => (
              <div className="col-lg-3 mb-40" key={index}>
                {value.resources_pdf ? (
                  <a href={imgUrl + value.resources_pdf} target="_blank">
                    <div className="manblog">
                      <div className="manblog-image mb-10">
                        <img src={value.resources_thumbnail ? imgUrl + value.resources_thumbnail : constant.DEFAULT_IMAGE} className='img-fluid' alt={value.resources_title} />
                      </div>
                      <div className="manblog-content ">
                        <h4 className="text-center" style={{ fontWeight: "bold", border: "1px solid #69913b", backgroundColor: "#69913b", color: "#fff", padding: "10px", borderRadius: "15px" }}>{value.resources_title}</h4>
                      </div>
                    </div>
                  </a>
                ) : (
                  <div className="manblog">
                    <div className="manblog-image mb-10">
                      <img src={value.resources_thumbnail ? imgUrl + value.resources_thumbnail : constant.DEFAULT_IMAGE} className='img-fluid' alt={value.resources_title} />
                    </div>
                    <div className="manblog-content ">
                      <h4 className="text-center" style={{ fontWeight: "bold", border: "1px solid #69913b", backgroundColor: "#69913b", color: "#fff", padding: "10px", borderRadius: "15px" }}>{value.resources_title}</h4>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>

  )
}

export default Resources