import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

function VideoGallery() {

    const didMountRef = useRef(true);
    const [videoData, setVideoData] = useState([]);
    const [galleryImgUrl, setGalleryImgUrl] = useState("");
    const [loading, setLoading] = useState();
    const [pageContent, setpageContent] = useState({});
    const[headerImageUrl,setHeaderImageUrl] = useState('')
    useEffect(() => {
      if (didMountRef.current) {
        getPagesData();
        getVideoData()
      }
      didMountRef.current = false;
    }, []);
    const getPagesData = () => {
      const pageData = {
        slug: "video-gallery",
      };
  
      ApiService.postData("page-data", pageData).then((res) => {
        if (res.status == "success") {
          setpageContent(res.data)
          setHeaderImageUrl(res.header_image_path)
        }
      });
    };
    const getVideoData = () => {
      setLoading(true);
      ApiService.fetchData("gallery-video").then((res) => {
        if (res.status === "success") {
          setVideoData(res.videoData);
          setGalleryImgUrl(res.gallery_img_url);

          
        }
      });
    };
  return (

    <>
    
    <Header/>
    <div className="subheader">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <h1>{pageContent?.page_name}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{pageContent?.page_name}</li>
                        </ol>
                        </nav>
                </div>
            </div>
        </div>
        <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div>
    </div>
    <section className="section-gap-md">
                <div className="container">
                  <div className="row">
                  {videoData.map((value, index) => (
                    <div className="col-lg-4" key={index}>
    <a href={value.gallery_video_url} target="_blank">
      <img
        src={galleryImgUrl + value.gallery_image}
        alt=""
       className="w-100"
        height={"385px"}
      />
      <p className="text-center" style={{ color: "#C1272D", padding:"20px" }}>{value.gallery_title}</p>
    </a>
                    </div>
                      ))}
                    </div>
                      </div>
                      </section>

    <Footer/>
    </>

  )
}

export default VideoGallery