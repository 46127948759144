import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import constant from "../Services/constant";



function HomeImpactStories() {
    const didMountRef = useRef(true);
    const [storiesData, setStoriesData] = useState([]);
    const [storiesImgUrl, setStoriesImgUrl] = useState("");
  
    useEffect(() => {
      if (didMountRef.current) {
        FeaturedBlogdata();
      }
      didMountRef.current = false;
    }, []);
  
    const FeaturedBlogdata = () => {
      ApiService.fetchData("featured-stories").then((res) => {
        console.log(res);
        if (res.status === "success") {
            setStoriesData(res.storiesData);
            setStoriesImgUrl(res.stories_url);

        }
      });
    };



    const StoriesCarouselOptions = {
        loop: true,
        spaceBetween: 15,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 1,
            },
            1000: {
                slidesPerView: 3,
            },
        },
    };
    return (
        <section className='section-gap-md'>
            <div className='container-fluid'>
                <div className='row align-items-center'>
                    <div className='col-lg-4'>
                        <div className="section-title">
                            <span className="section-title__subtitle mb-10">Impact Stories</span>
                            <h2>Our Impact  <span>Stories</span></h2>
                            <h5>Giving help To Those Who Need It.</h5>
                        </div>
                        <p>Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.</p>
                        <a href="/impact-stories" className="cleenhearts-btn mt-20 mb-20">
                            <div className="cleenhearts-btn__icon-box">
                                <div className="cleenhearts-btn__icon-box__inner"><span className="icon-donate"><i className="ri-arrow-right-double-fill"></i></span></div>
                            </div>
                            <span className="cleenhearts-btn__text">Read More</span>
                        </a>
                    </div>
                    <div className='col-lg-8'>
                        <Swiper
                            {...StoriesCarouselOptions}
                        >
                              {storiesData?.map((list, index) => (
                            <SwiperSlide>
                                <div className='cause-item' key={index}>
                                    <div className='cause-item-tumbnil'>
                                        <a href={`/impact-stories/${list?.stories_slug}`}>
                                            <img src={list.stories_image !=null ? storiesImgUrl + list.stories_image : constant.DEFAULT_IMAGE}></img>
                                        </a>
                                    </div>
                                    <div className='content'>
                                        <h2 className='title'><a href={`/impact-stories/${list?.stories_slug}`}>{list.stories_name}</a></h2>
                                        <p className="mb-0">{list.stories_short_description}</p>
                                    </div>
                                </div>
                            </SwiperSlide>

))}

                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeImpactStories