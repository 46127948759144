import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
// import lgZoom from 'lightgallery/plugins/zoom';

function NewsMedia() {

    const didMountRef = useRef(true)
    const [pageData, setPageData] = useState({})
    const [headerImageUrl, setHeaderImageUrl] = useState('')
    const [mediaData, setMediaData] = useState([])
    const [mediaImgUrl, setMediaImgUrl] = useState("");
    useEffect(()=>{
        if(didMountRef.current){

            getPagesData()
            getMediaContent()

        }
        didMountRef.current =false
    })

    const getPagesData = () =>{
        const dataString = {
            slug: "news-media"
        }
        ApiService.postData("page-data",dataString).then((res)=>{
            if(res.status === "success"){
                setPageData(res.data)
                setHeaderImageUrl(res.header_image_path)

            }

        })

    }

    const getMediaContent = () =>{
        ApiService.fetchData("news-media").then((res)=>{
            if(res.status === "success"){
                setMediaData(res.newsimagedata)
                setMediaImgUrl(res.newsmedia_url);
            }
        })

    }

  return (
    <>    
    <Header/>
    <div className="subheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>{pageData?.page_name}</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{pageData?.page_name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="subheader-shape1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map1.png" alt="image"/>
				</div>
        <div className="subheader-shape2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/map2.png" alt="image"/>
				</div>
        <div className="subheader-line1">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector1.png" alt="image"/>
				</div>
        <div className="subheader-line2">
					<img src="https://themes.hibotheme.com/traba/wp-content/themes/traba/assets/images/page-banner/vector2.png" alt="image"/>
				</div>
      </div>
      
      <section className="section-gap-md">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="galarybox">
                <LightGallery
                  plugins={[lgThumbnail]}>
                  {mediaData.map((value, index) => (
                    <a href={mediaImgUrl + value.newsmedia} key={index}>
                      <img
                        src={mediaImgUrl + value.newsmedia}
                        alt=""
                        width={"100%"}
                        height={"385px"}
                      />
                      <p className="text-center" style={{ color: "#C1272D" }}>{value.newsmedia_title}</p>
                    </a>
                  ))}
                </LightGallery>
              </div>
            </div>
          </div>
        </div>
      </section>
    <Footer/>
    </>

  )
}

export default NewsMedia