import React, { useEffect, useRef, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { ApiService } from '../Services/apiservices'
import { Alert } from 'react-bootstrap';
function Footer() {

  const didMountRef = useRef(true)
  const [settingData, setSettingData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [footer1, setFooter1] = useState("");
  const [footer2, setFooter2] = useState("");
  const [footer3, setFooter3] = useState("");
  const [footer4, setFooter4] = useState("");
  const [newsletterDetails, setNewsletterDetails] = useState({
    newsletter_email: "",
  });
  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()
      getFooterData()
    }
    didMountRef.current = false
  })

  const getSettingData = () => {
    ApiService.fetchData("setting-data").then((res) => {
      if (res.status === "success") {
        setSettingData(res.sitesettings)
      }
    })

  }

  const getFooterData = () => {
    ApiService.fetchData("footer-data").then((response) => {
      console.log(response);
      if (response?.status === "success") {
        setFooter1(response?.footerData?.footer_desc1);
        setFooter2(response?.footerData?.footer_desc2);
        setFooter3(response?.footerData?.footer_desc3);
        setFooter4(response?.footerData?.footer_desc4);
      }
    });
  };
  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setNewsletterDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetNewsletterForm = () => {
    setNewsletterDetails({ newsletter_email: "" });
  };
  const newsletterProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("newsletterRequired");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
        myElements[i].style.border = '';
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!validEmailRegex.test(newsletterDetails.newsletter_email)) {
        setErrorMessage("Please enter valid Email Id");
        return false;
      }
      ApiService.postData('newsletter-process', newsletterDetails).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message)
          resetNewsletterForm();
          setTimeout(() => {
            setSuccessMessage('')
          }, 2000);
        } else {
          setErrorMessage(res.message)
          setTimeout(() => {
            setErrorMessage('')
          }, 2000);
        }
      });
    }
  };

  return (
    <>
      <BrowserView>
        <footer className='footer' style={{ background: 'url(/assets/images/ft-bg-1.jpg)' }}>
          <div className='footer-middle'>
            <div className='container-fluid'>
              <div className='row justify-content-between'>

                <div className='col-lg-2'>
                  <h5 className='footer-title'>Quick Links</h5>
                  <div className='footer-line'>
                    <span className="short-line"></span>
                    <span className="long-line"></span>
                  </div>
                  <div className='footer-list' dangerouslySetInnerHTML={{ __html: footer1 }}>
                  </div>
                </div>
                <div className='col-lg-3 footer-border-right'>
                  <h5 className='footer-title'>Our Programs</h5>
                  <div className='footer-line'>
                    <span className="short-line"></span>
                    <span className="long-line"></span>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='footer-list' dangerouslySetInnerHTML={{ __html: footer2 }}>

                      </div>
                    </div>
                    
                  </div>

                </div>

                <div className='col-lg-3 footer-border-right'>
                  <h5 className='footer-title'>Our Projects</h5>
                  <div className='footer-line'>
                    <span className="short-line"></span>
                    <span className="long-line"></span>
                  </div>
                  <div className='row'>
                    
                    <div className='col-lg-12'>
                      <div className='footer-list' dangerouslySetInnerHTML={{ __html: footer3 }}>

                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-4">
                  <div className='footer-right'>
                    <h5 className='footer-title'>Newsletter</h5>
                    <div className='footer-line'>
                      <span className="short-line"></span>
                      <span className="long-line"></span>
                    </div>
                    {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                    {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                    <p>Enter your email address to register to our newsletter subscription</p>
                    <div className="input-group footer-subscription-form">
                      <input type="email"
                        className="form-control" placeholder="Your email" name="newsletter_email" id="newsletter_email"
                        value={newsletterDetails.newsletter_email}
                        onChange={(e) => onTodoChange(e)}
                        fdprocessedid="cphvxs" />
                      <button className="btn01" type="button" fdprocessedid="48fuyk"
                        onClick={newsletterProcess}>Subscribe</button>
                    </div>
                    <h5 className='footer-title'>Follow Us On</h5>
                    <div className='footer-line'>
                      <span className="short-line"></span>
                      <span className="long-line"></span>
                    </div>
                    <ul className='socialmedia'>
                      {settingData.facebook_url != null ? (
                        <li ><a href={settingData.facebook_url} target="new"><i  className="ri-facebook-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.twitter_url != null ? (
                        <li ><a href={settingData.twitter_url} target="new"><i  className="ri-twitter-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.linkedin_url != null ? (
                        <li ><a href={settingData.linkedin_url} target="new"><i  className="ri-linkedin-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.youtube_url != null ? (
                        <li ><a href={settingData.youtube_url} target="new"><i  className="ri-youtube-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.instagram_url != null ? (
                        <li ><a href={settingData.instagram_url} target="new"><i  className="ri-instagram-line"></i></a></li>
                      ) : (
                        ""
                      )}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='contacts-item'>
                    <div className='contacts-item-icon'>
                      <img src='/assets/images/phone.png'></img>
                    </div>
                    <div className='contacts-content'>
                    <a href={"tel:" + settingData?.admin_mobile} className="title">{settingData?.admin_mobile}</a>
                     
                      <br/>
                      <a href={"tel:" + settingData?.admin_support_mobile} className="title">{settingData?.admin_support_mobile}</a>

                      <p className="sub-title">Mon-Fri 9am-6pm</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='contacts-item'>
                    <div className='contacts-item-icon'>
                      <img src='/assets/images/email.png'></img>
                    </div>
                    <div className='contacts-content'>
                      <a href={"mailto:" + settingData?.admin_support_email} className="title">{settingData?.admin_support_email}</a>
                      <p className="sub-title">Mail us on</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='contacts-item'>
                    <div className='contacts-item-icon'>
                      <img src='/assets/images/loc2.png'></img>
                    </div>
                    <div className='contacts-content'>
                      <a href="https://maps.app.goo.gl/HuBSQPufMw2KDGcM6" className="title" target='_blank'>{settingData?.address}</a>
                      {/* <p className="sub-title">795 South Park Avenue</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className='copyright tx-center'>© 2024 Pragati Bharat. All rights reserved</div>


      </BrowserView>

      <MobileView>

        <footer className='footer' style={{ background: 'url(/assets/images/ft-bg-1.jpg)' }}>
          <div className='footer-middle'>
            <div className='container-fluid'>
              <div className='row justify-content-between'>

                <div className='col-lg-2'>
                  <h5 className='footer-title'>Quick Links</h5>
                  <div className='footer-line'>
                    <span className="short-line"></span>
                    <span className="long-line"></span>
                  </div>
                  <div className='footer-list' dangerouslySetInnerHTML={{ __html: footer1 }}>
                  </div>
                </div>
                <div className='col-lg-3 footer-border-right'>
                  <h5 className='footer-title'>Our Programs</h5>
                  <div className='footer-line'>
                    <span className="short-line"></span>
                    <span className="long-line"></span>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='footer-list' dangerouslySetInnerHTML={{ __html: footer2 }}>

                      </div>
                    </div>
                    
                  </div>

                </div>
                <div className='col-lg-3 footer-border-right'>
                  <h5 className='footer-title'>Our Projects</h5>
                  <div className='footer-line'>
                    <span className="short-line"></span>
                    <span className="long-line"></span>
                  </div>
                  <div className='row'>
                    
                    <div className='col-lg-12'>
                      <div className='footer-list' dangerouslySetInnerHTML={{ __html: footer3 }}>

                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-lg-5">
                  <div className='footer-right'>
                    <h5 className='footer-title'>Newsletter</h5>
                    <div className='footer-line'>
                      <span className="short-line"></span>
                      <span className="long-line"></span>
                    </div>
                    {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                    {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                    <p>Enter your email address to register to our newsletter subscription</p>
                    <div className="input-group footer-subscription-form">
                      <input type="email"
                        className="form-control" placeholder="Your email" name="newsletter_email" id="newsletter_email"
                        value={newsletterDetails.newsletter_email}
                        onChange={(e) => onTodoChange(e)}
                        fdprocessedid="cphvxs" />
                      <button className="btn01" type="button" fdprocessedid="48fuyk"
                        onClick={newsletterProcess}>Subscribe</button>
                    </div>
                    <h5 className='footer-title'>Follow Us On</h5>
                    <div className='footer-line'>
                      <span className="short-line"></span>
                      <span className="long-line"></span>
                    </div>
                    <ul className='socialmedia'>
                      {settingData.facebook_url != null ? (
                        <li><a href={settingData.facebook_url} target="new"><i className="ri-facebook-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.twitter_url != null ? (
                        <li><a href={settingData.twitter_url} target="new"><i className="ri-twitter-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.linkedin_url != null ? (
                        <li><a href={settingData.linkedin_url} target="new"><i className="ri-linkedin-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.youtube_url != null ? (
                        <li><a href={settingData.youtube_url} target="new"><i className="ri-youtube-fill"></i></a></li>
                      ) : (
                        ""
                      )}
                      {settingData.instagram_url != null ? (
                        <li><a href={settingData.instagram_url} target="new"><i className="ri-instagram-line"></i></a></li>
                      ) : (
                        ""
                      )}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='contacts-item'>
                    <div className='contacts-item-icon'>
                      <img src='/assets/images/phone.png'></img>
                    </div>
                    <div className='contacts-content'>
                      <a href={"tel:" + settingData?.admin_support_mobile} className="title">{settingData?.admin_support_mobile}</a>
                      <p className="sub-title">Mon-Fri 9am-6pm</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='contacts-item'>
                    <div className='contacts-item-icon'>
                      <img src='/assets/images/email.png'></img>
                    </div>
                    <div className='contacts-content'>
                      <a href={"mailto:" + settingData?.admin_support_email} className="title">{settingData?.admin_support_email}</a>
                      <p className="sub-title">Mail us on</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='contacts-item'>
                    <div className='contacts-item-icon'>
                      <img src='/assets/images/loc2.png'></img>
                    </div>
                    <div className='contacts-content'>
                      <a href="https://maps.app.goo.gl/HuBSQPufMw2KDGcM6" className="title" target='_blank'>{settingData?.address}</a>
                      {/* <p className="sub-title">795 South Park Avenue</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className='copyright tx-center'>© 2024 Pragati Bharat. All rights reserved</div>

      </MobileView>

    </>
  )
}

export default Footer